<template>
<el-dropdown @command="handleItemClick">
    <span class="f-center">{{langValue}}<i class="el-icon-arrow-down el-icon--right"></i></span>

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :command="i.value" v-for="i in langList" :key="i.value">{{i.label}}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { useI18n, intl } from '@tci18n/vue2';
import { getStorage, setStorage } from "@/utils/storage";

const { getLangsList } = useI18n();


/**
 *@Description 系统 语言 类型 .
 *@Return
 **/
export const returnSysLang = (f = 'o') => {
  const o = {
    'en-us': 'English',
    'ja': intl.$t("日本語"),
    'zh-cn': intl.$t("中文（简体）")
  };
  const arr = [{
    value: 'en-us',
    label: 'English'
  }, {
    value: 'ja',
    label: intl.$t("日本語")
  }, {
    value: 'zh-cn',
    label: intl.$t("中文（简体）")
  }];

  if (f === 'o') return o;
  if (f === 'arr') return arr;
};

export default {
  name: 'LoginMain',
  data() {
    return {
      langValue: 'English', // 语言
      langData: returnSysLang(), // 语言 数据
      langList: returnSysLang('arr') // 语言 数据
    };
  },
  created() {
    this.initData();
  },
  methods: {
    /**
     *@Description 初始化 数据 .
     *@Return
     **/
    initData() {
      this.langList = getLangsList();
      console.log('this.langList', this.langList);
      const la = getStorage('hrms_language') || 'en-us';
      this.langValue = this.langData[la];
      console.log('this.langValue', this.langValue);
    },
    /**
     *@Description 搜索 框 事件 .
     *@Return
     **/
    handleItemClick(e) {
      const { switchLang } = useI18n();
      switchLang(e);

      this.langValue = this.langData[e];
      setStorage('hrms_language', e);
      this.$emit('emitLang', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 110px;
  height: 32px;
  border-radius: 32px;
  padding: 0 10px;
  cursor: pointer;
  //color: #fff;
  border: 1px solid #497CF6;
}
</style>
